<template>
  <div class="card card-custom card-transparent" :dir="$t('clan')=='ar'?'rtl':'ltr'">
    <div v-if="submitStatus === 'ERROR'">
      <b-alert show variant="danger" :style="$t('clan')=='ar'?'text-align:right':''"
        ><i class="fas fa-exclamation-circle text-white mr-3"></i>

        {{$t('Alert')}}</b-alert
      >
    </div>
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">1</div>
                <div class="wizard-label">
                  <div class="wizard-title ">{{$t('New Client')}}</div>
                  <div class="wizard-desc mr-5">{{$t('Create New Client')}}</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">2</div>
                <div class="wizard-label">
                  <div class="wizard-title mr-5">{{$t('Account Information')}}</div>
                  <div class="wizard-desc mr-5">{{$t('Set Email and Password')}}</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">3</div>
                <div class="wizard-label">
                  <div class="wizard-title mr-5">{{$t('Pricing Information')}}</div>
                  <div class="wizard-desc mr-5">{{$t('Add Pricing')}}</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">4</div>
                <div class="wizard-label">
                  <div class="wizard-title mr-5">{{$t('Completed')}}</div>
                  <div class="wizard-desc mr-5">{{$t('Review and Submit')}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <!--begin: Wizard Form-->
                <form class="form mt-0" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.client.name.$error }"
                    >
                      <label :style="$t('clan')=='ar'?'float:right':''">{{$t('Full Name')}}</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="fname"
                        :placeholder="$t('Full Name')"
                        v-model="client.name"
                        :class="{ 'form-input--error': $v.client.name.$error }"
                        :state="$v.client.name.$error === true ? false : null"
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                    </div>
                    <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.client.phone.$error }"
                    >
                      <label :style="$t('clan')=='ar'?'float:right':''">{{$t('phone')}}</label>
                      <b-form-input
                        type="tel"
                        class="form-control form-control-solid form-control-lg"
                        name="phone"
                        onKeyPress="if(this.value.length==10) return false;"
                        pattern="/^-?\d+\.?\d*$"
                        :placeholder="$t('phone')"
                        v-model="client.phone"
                        :class="{ 'form-input--error': $v.client.phone.$error }"
                        :state="$v.client.phone.$error === true ? false : null"
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                      <div class="error" v-if="!$v.client.phone.minLength" :style="$t('clan')=='ar'?'text-align:right':''">
                       {{$t("feildPhone")}}
                        {{ $v.client.phone.$params.minLength.min }}  {{$t("num")}}.
                      </div>
                    </div>
                    <div
                      class="form-group"
                      :class="{
                        'form-group--error': $v.client.tookan_foreign_id.$error,
                      }"
                    >
                      <label :style="$t('clan')=='ar'?'float:right':''">{{$t('Tokan')}}</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="tookan_foreign_id"
                        :placeholder="$t('Tokan')"
                        v-model="client.tookan_foreign_id"
                        :class="{
                          'form-input--error':
                            $v.client.tookan_foreign_id.$error,
                        }"
                        :state="
                          $v.client.tookan_foreign_id.$error === true
                            ? false
                            : null
                        "
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div
                          class="form-group"
                          :class="{
                            'form-group--error': $v.client.long.$error,
                          }"
                        >
                          <label :style="$t('clan')=='ar'?'float:right':''">{{$t('long')}}</label>
                          <b-form-input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            name="Location Longitude"
                            :placeholder="$t('long')"
                            v-model="client.long"
                            :class="{
                              'form-input--error': $v.client.long.$error,
                            }"
                            :state="
                              $v.client.long.$error === true ? false : null
                            "
                          />
                          <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div
                          class="form-group"
                          :class="{ 'form-group--error': $v.client.lat.$error }"
                        >
                          <label :style="$t('clan')=='ar'?'float:right':''">{{$t('lat')}}</label>
                          <b-form-input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            name="state"
                            :placeholder="$t('lat')"
                            v-model="client.lat"
                            :class="{
                              'form-input--error': $v.client.lat.$error,
                            }"
                            :state="
                              $v.client.lat.$error === true ? false : null
                            "
                          />
                         <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <label :style="$t('clan')=='ar'?'float:right':''">{{$t('City')}}</label>

                        <div
                          class="form-group"
                          :class="{
                            'form-group--error': $v.client.city.$error,
                          }"
                        >
                          <select
                            class="form-control form-control-lg form-control-solid"
                            v-model="client.city"
                            :class="{
                              'form-select--error': $v.client.city.$error,
                            }"
                          >
                            <template v-for="zone in client.zones">
                              <option
                                :key="zone.id"
                                :value="zone.name_ar"
                                :selected="
                                  client.city == zone.name_ar ? true : false
                                "
                              >
                                {{ zone.name_ar }}
                              </option>
                            </template>
                          </select>
                          <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div
                          class="form-group"
                          :class="{
                            'form-group--error': $v.client.area.$error,
                          }"
                        >
                          <label :style="$t('clan')=='ar'?'float:right':''">{{$t('Area')}}</label>
                          <b-form-input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            name="state"
                            :placeholder="$t('Area')"
                            v-model="client.area"
                            :class="{
                              'form-input--error': $v.client.area.$error,
                            }"
                            :state="
                              $v.client.area.$error === true ? false : null
                            "
                          />
                          <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.client.email.$error }"
                    >
                      <label :style="$t('clan')=='ar'?'float:right':''">{{$t('Email')}}</label>
                      <b-form-input
                        type="email"
                        class="form-control form-control-solid form-control-lg"
                        name="Email"
                        placeholder="example@email.com"
                        v-model="client.email"
                        :class="{ 'form-input--error': $v.client.email.$error }"
                        :state="$v.client.email.$error === true ? false : null"
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                    </div>
                    <div
                      class="form-group"
                      :class="{
                        'form-group--error': $v.client.password.$error,
                      }"
                    >
                      <label :style="$t('clan')=='ar'?'float:right':''">{{$t('Password')}}</label>
                      <b-form-input
                        type="password"
                        class="form-control form-control-solid form-control-lg"
                        name="password"
                        :placeholder="$t('Password')"
                        v-model="client.password"
                        :class="{
                          'form-input--error': $v.client.password.$error,
                        }"
                        :state="
                          $v.client.password.$error === true ? false : null
                        "
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                      <div class="error" v-if="!$v.client.password.minLength">
                        Password must have at least
                        {{
                          $v.client.password.$params.minLength.min
                        }}
                        Characters.
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content" dir="ltr">
                    <div class="col-xl-12">
                        <label :style="$t('clan')=='ar'?'float:right':''">{{$t('SelectTarrif')}}</label>

                        <div
                          class="form-group"
                          
                        >
                          <select
                            class="form-control form-control-lg form-control-solid"
                            v-model="client.selected_tarrif"
                           
                          >
                            <template v-for="tarrif in tarrifs">
                              <option
                                :key="tarrif.id"
                                :value="tarrif.id"
                                :selected="
                                  tarrif.id == client.selected_tarrif ? true : false
                                "
                              >
                                {{ tarrif.tarrif_name }}
                              </option>
                            </template>
                          </select>
                          <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                        </div>
                      </div>
                  </div>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content" :style="$t('clan')=='ar'?'text-align:right':''">
                    <div class="mb-10 font-weight-bold text-dark">
                      {{$t('rev')}}
                    </div>
                    <div class="border-bottom mb-5 pb-5">
                      <div class="font-weight-bold mb-3">{{$t('info')}}:</div>
                      <div class="line-height-md">
                        {{ client.name }}
                        <br />
                        {{$t('phone')}}: {{ client.phone }} <br />
                        {{$t('long')}}: {{ client.long }} <br />
                        {{$t('lat')}}: {{ client.lat }} <br />
                        {{$t('City')}}: {{ client.city }} <br />
                        {{$t('Area')}}: {{ client.area }} <br />
                      </div>
                    </div>
                    <div class="mb-5 pb-5">
                      <div class="font-weight-bold mb-3">
                        {{$t('acinfo')}}:
                      </div>
                      <div class="line-height-md">
                        {{$t('Email')}}: {{ client.email }}
                        <br />
                        {{$t('Password')}}: {{ client.password }} <br />
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        {{$t('Previous')}}
                      </button>
                    </div>
                    <div>
                      <button
                        @click.prevent="submit"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        {{$t('Submit')}}
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                      >
                        {{$t('Next Step')}}
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "CreateClient",

  data() {
    return {
      client: {
        zones: [],
        name: null,
        phone: null,
        long: null,
        lat: null,
        tookan_foreign_id: null,
        area: null,
        email: null,
        password: null,
        city: null,
        pricing_mode: "geofence_mode",
        additional_charge: 1,
        additional_distance: 1,
        base_charge: 1.5,
        base_distance: 2,
        distance_pricing:'',

        selected_tarrif:''
      },
      submitStatus: null,
      toggleValue: "OFF",
      toggled: false,
      tarrifs:[],
      distance_zones:[
        {id:1 , name:'Zone1' , distance:1.25 , price:2.5}
      ]
    };
  },
  validations: {
    client: {
      name: {
        required,
      },
      phone: {
        required,
        minLength: minLength(10),
      },
      long: {
        required,
      },
      lat: {
        required,
      },
      tookan_foreign_id: {
        required,
      },
      area: {
        required,
      },
      email: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      city: {
        required,
      },
    },
  },
  updated(){
    //console.log(this.client.selected_tarrif)
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Clients", route: "/admin/clients" },
      { title: "New Client" },
    ]);

    this.client.zones = await this.$store.dispatch("clients/getZones");
    var tarrifs = await this.$store.dispatch('clientTarrif/loadAll')
    this.tarrifs = tarrifs.data
    //console.log(this.tarrifs)

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {});

    // Change event
    wizard.on("change", function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    add(){
      this.distance_zones.push({id:this.distance_zones.length+1 , 
      name:'zone'+(parseInt(this.distance_zones.length)+1) , distance:1, price:2})
    },
    remove(id){
      this.distance_zones = this.distance_zones.filter(s=>s.id !==id)
    },
    setToggle() {
      if (this.toggled === false) {
        this.toggleValue = "ON";
        this.toggled = true;
        this.client.pricing_mode = "distance_mode";
      } else {
        this.toggleValue = "OFF";
        this.toggled = false;
        this.client.pricing_mode = "geofence_mode";
      }
    },
    async submit() {
      var obj = JSON.stringify(this.distance_zones)
      
      this.$v.$touch();
      if (this.$v.$invalid) {
        try{
          

          await this.$store.dispatch("clients/create", this.client)
          
        //console.log(response);
        
      }catch(error){
        console.log(error.response.status)
        console.log(error.response)
        var errmessage = error.response.data.message
        var mes =''
        //error.response.data.errors.forEach(error => console.log(error))
        if(error.response.data.errors.name !==undefined){
          var name = error.response.data.errors.name[1]
          mes = mes + name
        }
        if(error.response.data.errors.email !==undefined){
          var email = error.response.data.errors.email
          mes = mes + email
        }
        if(error.response.data.errors.password !==undefined){
          var password = error.response.data.errors.password
          mes = mes + password
        }
        if(error.response.data.errors.phone !==undefined){
          var phone = error.response.data.errors.phone[1]
          mes = mes + phone
        }
       
        
        
        
        Swal.fire({
          title: errmessage,
          text: mes ,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      }
        this.submitStatus = "ERROR";
      } else {
        this.client.distance_pricing = obj
        //console.log(this.client.distance_pricing)
        
          await this.$store.dispatch("clients/create", this.client);
        
        

        Swal.fire({
          title: "",
          text: "The client has been created successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        this.$router.push({ name: "Clients" });
        this.$store.dispatch("clients/loadAll");

        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
    },
  },
};
</script>

<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.error {
  color: rgb(235, 128, 41);
}
</style>